<template>
    <div class="column is-12" >
        <div v-for="(evento,index) in eventos" :key="index" :class="tipoEventoStyle(evento)"  >
            <div class="column is-2 has-text-centered">
                <b :class="{ 'is-size-7  is-paddingless' : (tipoVisao=='mes') , 'is-size-6 is-paddingless is-size-7-mobile':  (tipoVisao=='dia') }" >{{evento.hour}}</b>
                <b-icon v-if="evento.tipoAgendamentoCor"
                        :style="tipoAgendamentoStyle(evento)"    
                        size="is-small"                                            
                        icon="square-rounded"></b-icon>
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && !isConfirmado(evento)" :title="$t('AGENDA.PACIENTENAOCONFIRMADO')" class="has-text-danger">!</b> 
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isConfirmado(evento)" :title="$t('AGENDA.PACIENTECONFIRMADO')" class="has-text-success">&radic;</b> 
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isEncaixe(evento)" :title="$t('AGENDA.PACIENTEENCAIXE')" class="has-text-black">&crarr;</b> 
                
                                       
            </div>
            <div class="column is-10" style="margin-left: 5px;">

                <span class="is-pulled-right">

                    <b-button class="is-pulled-right" position="is-bottom-left"  v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento)"   :title="$t('AGENDA.BLOQUEARHORARIO')" 
                        icon-left="cancel" type="is-black"   @click="bloquearHorario(evento)" ></b-button>                                                          
                    <b-button class="is-pulled-right" position="is-bottom-left" v-show="isBloqueio(evento) || isReservado(evento) "  
                        :title="$t('AGENDA.DESBLOQUEARHORARIO')" icon-left="lock-open"  @click="desbloquearHorario(evento.id)"   ></b-button> 
                    <b-button class="is-pulled-right" position="is-bottom-left" v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento)" :title="$t('AGENDA.INCLUIRPACIENTE')"  
                        icon-left="account-plus" @click="incluirNovoHorarioDia(evento, evento.dataHora)"></b-button>                                

                    <b-dropdown
                        v-if="isAgendado(evento)"
                        append-to-body                    
                        position="is-bottom-left"                        
                        aria-role="list"
                        trap-focus >
                        <template #trigger="{ active }">
                            <b-button tag="a" :icon-right="tipoIcone(active)" />                        
                        </template>

                    <b-dropdown-item aria-role="listitem"   @click="criarGuia(evento)">{{$t('AGENDA.CRIARGUIA')}} </b-dropdown-item>                        
                    <b-dropdown-item aria-role="listitem"  @click="verGuiasPaciente(evento)">{{$t('AGENDA.VERGUIAPACIENTE')}} </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem"  @click="incluirNovoHorarioDia(evento, evento.dataHora)">Editar</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" v-if="!isConfirmado(evento)"  @click="confirmarPaciente(evento)">Confirmar </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem"  @click="confirmarCancelamento(evento)">Cancelar </b-dropdown-item>                    
                    <b-dropdown-item v-if="evento.guiaAgendaExamesNome && evento.guiaAgendaExamesNome.length>0" aria-role="listitem"  @click="reagendamentoExames(evento)">Reagendar Exames </b-dropdown-item>  
                    <hr class="dropdown-divider" aria-role="menuitem">
                    <b-dropdown-item aria-role="listitem"  @click="modalIncluirAnexo(evento)"><b-icon  icon="link" ></b-icon>Anexos</b-dropdown-item>

                    <b-dropdown-item aria-role="listitem"  @click="abrirImprimir(evento)"><b-icon  icon="printer" ></b-icon>Imprimir </b-dropdown-item>
                    <hr class="dropdown-divider" aria-role="menuitem">                    
                    <b-dropdown-item aria-role="listitem"  @click="exibirHistorico(evento)"><b-icon  icon="clock" ></b-icon>Histórico </b-dropdown-item>                    
                                        
                </b-dropdown>  

                    <b-dropdown
                        v-else-if="isReservado(evento)"
                        append-to-body                    
                        position="is-bottom-left"                        
                        aria-role="list"
                        trap-focus >
                        <template #trigger="{ active }">
                            <b-button tag="a" :icon-right="tipoIcone(active)" />                        
                        </template>
                    <b-dropdown-item aria-role="listitem"  @click="incluirNovoHorarioDia(evento, evento.dataHora)">Editar</b-dropdown-item>                                                            
                </b-dropdown>                               


                </span>
                
                <div class="is-flex is-justify-content-space-between mediaScreen-column">
                    <div>
                        <p :class="{ 'is-size-7 text-nowrap' : (tipoVisao=='mes') , 'is-size-5 is-size-7-mobile':  (tipoVisao=='dia') }">
                            <b>{{tratarNomePaciente(evento)}}</b>   {{(evento.pacienteCelular) ? evento.pacienteCelular : evento.pacienteFone}} </p>
                        <p v-if="evento.pacienteResponsavelNome" :class="{ 'is-size-7 text-nowrap' : (tipoVisao=='mes') , 'is-size-5 is-size-7-mobile':  (tipoVisao=='dia') }">
                            {{tratarNome(evento.pacienteResponsavelNome)}}   {{(evento.pacienteResponsavelCelular) ? evento.pacienteResponsavelCelular : evento.pacienteResponsavelFone}}
                        </p>
                        <p :class="{ 'is-size-7 text-nowrap' : (tipoVisao=='mes') , 'is-size-5 is-size-7-mobile':  (tipoVisao=='dia') }">
                            {{evento.convenioNome}}
                        </p>
                    </div>
                    <div v-if="tipoVisao=='dia'" class="is-inline size-5 is-size-7-mobile mediaScreen-start"> 
                        <p class="is-flex is-flex-direction-column mr-2 is-full-mobile">
                            {{tratarNome(evento.tipoAgendamentoNome)}} - {{evento.funcionarioNome}}
                        </p>
                        <p class="is-flex is-flex-direction-column mr-2">
                            <span v-if="evento.guiaAgendaExamesNome">{{evento.guiaAgendaExamesNome.map(e => e).join(",")}}</span>
                        </p>                 
                    </div>
                </div>                
                
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    
    .text-nowrap{
        text-overflow: ellipsis;
        white-space: nowrap;        
        overflow: hidden;        
        max-width: 10rem;  
    }

    @media screen and (min-width: 769px){
        .mediaScreen-start{
            justify-content: flex-end;
        }
    }

    @media screen and (max-width: 769px){
        .mediaScreen-column{
            flex-direction: column;
        }
    }

</style>

<script>

import moment from 'moment';
import historicoAlteracoes from "@/components/historicoAlteracoes.vue";
import modalCancelandoAgenda from '@/components/atendimento/modalCancelandoAgenda'
import modalReagendamentoExames from './modalReagendamentoExames.vue';
import modalAnexosGenerico from "@/components/modalAnexosGenerico.vue";


export default {
    props: {
        eventos: Array,
        tipoVisao: String,
        agendaWorklist: {
            type: Boolean,
            default: false
        }               
    },
    methods: {

        exibirHistorico(item) {
            this.$buefy.modal.open({
                parent: this,
                component: historicoAlteracoes,
                props: {
                    tabela: '',
                    chave: item.id,
                    toggle: true,
                    agendaLog: true,
                },
                hasModalCard: true,
                trapFocus: true
            })
        },          
        tipoIcone(active){
            
            if(this.tipoVisao =="mes"){
                return active ? 'menu-up' : 'menu-down';
            }else{
                return 'format-list-bulleted';
            }

        },
        tipoAgendamentoStyle(evento) {
            return 'color: #' + evento.tipoAgendamentoCor + '!important'
        },
        tipoEventoStyle(evento) {
            let classes = 'box columns is-mobile is-gapless is-paddingless';
            
            if(this.isBloqueio(evento))
                classes += ' has-text-light has-background-dark';

            if(this.isAgendado(evento) && this.tipoVisao=="dia")
                classes += ' has-background-info has-text-light';

            if(this.isReservado(evento) && this.tipoVisao=="dia")
                classes += ' has-background-dark has-text-danger-light';                
                
            return classes;                

        },    
  
        tratarNomePaciente(evento){

            if(evento.pacienteNome){
                return evento.pacienteNome.toLowerCase();
            }else if(evento.id>0 && !evento.pacienteNome && evento.observacao=='Bloqueado'){
                return this.$t('AGENDA.HORARIOBLOQUEADO');
            }else if(evento.id>0 && !evento.pacienteNome && (evento.observacao=='Reservado' || evento.observacao=='RESERVADO_TEMPORARIAMENTE')){
                // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                return this.$t('AGENDA.HORARIORESERVADO');
            }
            
        },
        tratarNome(nome){            
            if(!nome){
                return null;
            }
            return nome.toLowerCase();                        
        },

        isReservado(evento){              
            // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
            return (evento.id && !evento.pacienteNome && (evento.observacao=='Reservado' || evento.observacao=='RESERVADO_TEMPORARIAMENTE'));                
        },                 
        isBloqueio(evento){              
            
            return (evento.id && !evento.pacienteNome && evento.observacao=='Bloqueio');                
        }, 
        isAgendado(evento){              
            
            return (evento.pacienteNome);                
        },  
        isConfirmado(evento){              
            
            return (evento.pacienteConfirmado);                
        },  
        isEncaixe(evento){              
            
            return (evento.encaixe);                
        },                          
        desbloquearHorario(id){                
            const evento = {id: id};
            this.agendaStatus(evento, 'cancelar');
        },            
        bloquearHorario(evento){    
            const bloqueio = {
                data: evento.dataHora,
                observacao: 'Bloqueio',
                idPaciente: 0,
                idTipoAgendamento: evento.tipoAgendamentoId,
                funcionarioId: null,
                paciente: { id: 0 },
                tipoAgendamento: { id: evento.tipoAgendamentoId},
                intervaloDuracaoAtendimento: evento.intervaloDuracaoAtendimento
            }

            this.adicionarAgendamento(bloqueio);
        }, 
        confirmarPaciente(evento){
            this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('AGENDA.CONFIRMAPACIENTE'),
                type: 'is-info',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {
                    this.agendaStatus(evento,'confirmar')
                }
            })
        },                   
        confirmarCancelamento(evento){

            const escalasEncontradas = this.eventos.filter(ev => ev.id == evento.id);
            
            this.$buefy.modal.open({
                parent: this,
                component: modalCancelandoAgenda,
                props: {
                    id: evento.id,                        
                    exames: evento.guiaAgendaExamesNome,
                    dataHoraInicio: escalasEncontradas[0].dataHora,
                    dataHoraFim: evento.dataHoraFim,
                },
                events: {
                    loadData: this.loadDataAgendaItem
                },
                hasModalCard: true,
                trapFocus: true
            })

        },
        reagendamentoExames(evento){
            if (evento.guiaAgendaExamesNome.length > 1) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalReagendamentoExames,
                    props: {
                        evento: evento                                        
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            }
            else {
                this.$router.push({ name: 'inspecao',params: { reagendamento: evento }  });  
            }
        },
        loadDataAgendaItem(){
            this.$emit('loadData');
            this.$emit('loadDataDia')
        },
         criarGuia(evento){
            this.$router.push({
                name: 'novaguia', params: {
                    agendamento: evento.id,
                    pacienteId: evento.pacienteId,
                    tipoAgendamentoid: evento.tipoAgendamentoId,
                    tipoAgendamentoNome: evento.tipoAgendamentoNome,
                    fluxoAgenda: true
                }
            })             
         },   
         verGuiasPaciente(evento){
             this.$router.push({ name: 'guia-list', query: { pacienteId: evento.pacienteId } })
         },
         abrirImprimir(evento){            
            this.$emit("abrirImprimir", { id: evento.id });
         },
         modalIncluirAnexo(evento) {
            this.$buefy.modal.open({
                parent: this,
                component: modalAnexosGenerico,
                props: {
                    id: evento.id,
                    guiaId: evento.guiaId,
                    titleName: 'Anexos de Agendamento',
                    controller: {
                        mainController: 'atendimento',
                        methodAnexoController: 'anexoLaudo',
                        methodRemoverController: 'excluirAnexoLaudo'
                    },
                    tipoParam: {
                        firstType: 'agendaId',
                        secondType: 'id',
                    },
                },
                hasModalCard: true,
                trapFocus: true
            });
         },
         incluirNovoHorarioDia(evento,dataHora){             
            this.$emit("incluirNovoHorarioDia", evento,dataHora);
         },         
        async agendaStatus(evento,acao) {
            const params = [];
            if (evento.id) params.push(`id=${evento.id}`);

            params.push(`acao=${acao}`);

            try {
                const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                const res = await this.$http.get(url);
                this.$emit('loadData')
            } catch (e) {
                console.error(e);
            }

        },

        async adicionarAgendamento(evento) {
            const dataHoraFim = this.$moment(evento.data).add( (evento.intervaloDuracaoAtendimento), 'm');
            const params = [];
            if (evento.id) params.push(`id=${evento.id}`);
            params.push(`pacienteId=${evento.paciente.id}`);
            params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`);
            if(evento.funcionarioId == null) evento.funcionarioId = 0;
            params.push(`funcionarioId=${evento.funcionarioId}`);
            params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
            params.push(`dataHoraFim=${moment(dataHoraFim).format('YYYY-MM-DDTHH:mm:ss')}`);
            params.push(`observacao=${evento.observacao ? evento.observacao : ''}`);
            if (this.$route.query.unidadeId != null)
                params.push(`unidadeId=${this.$route.query.unidadeId}`)
            else
                params.push('unidadeid=');

            try {
                let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                if (evento.id) {
                    url = `/api/agenda/agendaEditar?${params.join('&')}`;
                }

                this.isLoading = true;
                const res = await this.$http.post(url)
                        .then(res => res.data)
                        .then(data => {  
                            
                            if(data.mensagem){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message:data.mensagem,
                                    type: 'is-danger',
                                    queue: false
                                })
                            }

                            this.$emit('loadData')
                                                        
                        })                  
                
                
            } catch (e) {                    
                if (e.status === 404) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.ERROAOSALVAR'),
                        type: 'is-danger',
                        queue: false
                    })
                }
                console.error(e);
                
            }

        },                          
                
    }
};
</script>